import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import { Link } from "gatsby"
import _ from "lodash"
import Seo from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import { FIND_A_PROPERTY_PAGE_URL, VALUATION_PAGE_URL } from "../components/common/site/constants"

const NotFoundPage = (props) => {


  var [isVacancyPage, setIsVacancyPage] = useState(false);
  useEffect(() => {
    if (!_.isEmpty(props.location?.href)) {
      if (props.location?.href.indexOf("vacancy") > 0) {
        setIsVacancyPage(true);
      }
    }
  })

  return (
    <div className="wrapper error-details">
      <Header />
      <Seo title="404: Not found" />
      <div className="error-page">
        <Container>
          <Row>
            <Col lg={12} className="main-banner content">

              <>
                <h1>Oops! Page Cannot Be Found</h1>
                <p>The page you are looking for may have moved or it might not exist anymore. Try searching with the menu at the top of the page or the using the buttons below.</p>
              </>
              <div className="btn-wrap">
                <Link class="btn btn-secondary" to={`/new-homes/our-developments`}>
                  <span>Speak to our team</span>I’m a developer
                </Link>
                <Link class="btn" to={`/estate-agent-network`}>
                  <span>Our Southeast brands</span>Explore our Network
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>)
}

export default NotFoundPage
